import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/l2f/l2f"

export default function l2f() {
    return (
         <Layout>
            <SEO title="L2.F" />
            <Content />
         </Layout>
    )
}
