import React from 'react'
import Section from "../global/Section"
import Banner from "../../images/l2f/banner.png"
import overView1 from "../../images/l2f/overview1.png"
import overView2 from "../../images/l2f/overview2.png"
import overView3 from "../../images/l2f/overview3.png"
import connectWallet1 from "../../images/l2f/connectWallet1.png"
import connectWallet2 from "../../images/l2f/connectWallet2.png"
import connectWallet3 from "../../images/l2f/connectWallet3.png"
import dwL2_1 from "../../images/l2f/dwonL2-1.png"
import dwL2_2 from "../../images/l2f/dwonL2-2.png"
import dwStrategy1 from "../../images/l2f/dwStrategy-1.png"
import dwStrategy2 from "../../images/l2f/dwStrategy-2.png"
import dwStrategy3 from "../../images/l2f/dwStrategy-3.png"
import history1 from "../../images/l2f/history1.png"
import history2 from "../../images/l2f/history2.png"
import mvp from "../../images/l2f/mvp.png"
import main from "../../images/l2f/main.png"
import mobile from "../../images/l2f/mobile.png"


export default function l2f() {
    return (
        <>
          <Section extraMargin={"8rem"}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="font-w-extrabold">
              Layer2.Finance
            </h2>
            <p>Layer2.Finance aims to bring mass audiences to the existing Decentralized Finance (DeFi) ecosystem.</p>
            <img src={Banner} width="100%" className="pt-5" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Background</h4>
            <p className="d-grey pt-5">
              Layer2.Finance is a product that aims to solve the high transaction fee for DeFi(Decentralized Finanace) protocols. This can bring more small-fish users who are interested in investing in DeFi protocols. 
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">What are DeFi Protocols?</h4>
            <p className="d-grey pt-5">
             DeFi(Decentralized Finance) protocols allow users to lend their assets and earn 100X - 1000X higher annualized yields compared to traditional banking. This sounds pretty awesome but it has a huge weak point. The gas fee(Transaction Fee) is so expensive for small investors who are interested in DeFi protocols. L2F is built to solve this exact pain point.
            </p>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 pt-5">
            <img
              src={overView1}
              width="100%"
              className="imgSize-shadowStyle"
            />
            <p className="l-grey pt-4 text-center">
              Imaging DeFi is a city with many banks(DeFi Protocols) that allow you to save your assets and generate earnings. The only way to access those banks is a expensive limo(High gas fee).
            </p>
          </div>
        </div>
         <div className="row pt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img
              src={overView2}
              width="100%"
              className="imgSize-shadowStyle"
            />
             <p className="l-grey pt-4 text-center">
                Alice as a Whale investor can profit on Day1.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <img
              src={overView3}
              width="100%"
              className="imgSize-shadowStyle"
            />
              <p className="l-grey pt-4 text-center">
                Bob as small investor still lost after a year.
            </p>
          </div>
        </div>
        
      </Section>
      <Section container="container-fluid">
          <div className="container">
            <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Connect Wallet</h4>
            <p className="d-grey pt-5">
             Like a normal DApp(Decentralized Application), the users are required to connect their crypto wallets. It's just like you log on with your account in traditional applications. By connecting, the users can start to transfer their assets between their wallets and Layer2.Finance.
            </p>
          </div>
        </div>
          </div>

        <div className="row pt-5">
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={connectWallet1}
              width="100%"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={connectWallet2}
              width="100%"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={connectWallet3}
              width="100%"
            />
          </div>
        </div>
      </Section> 
    <Section container="container-fluid">
        <div className="container">
            <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Deposit to / Withdraw from L2</h4>
            <p className="d-grey pt-5">
              After user connected their wallet, users can deposit their assets to Layer2, which allows them to invest in different strategies. The strategies are the investment options of 3rd party DeFi protocols that L2F will move users' assets from L2 to L1 after an aggregation time out. On the other hand, users can choose to withdraw their assets from Layer2 to Layer1.
            </p>
          </div>
            </div>
        </div>
        <div className="row justify-content-center pt-5">
            <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={dwL2_1}
              width="100%"
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={dwL2_2}
              width="100%"
            />
          </div>
        </div>
    </Section>
    <Section container="container-fluid">
        <div className="row p-3 justify-content-center">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <h4 className="font-w-extrabold">Deposit to Strategies</h4>
            <p className="d-grey pt-5">
              After users store their assets in Layer2, they can start to invest their assets in Strategies. Strategies are different investment options that associate with L1 protocols. After deposit, the users will get stTokens. stToken is the liquidity token that users can redeem the underlying liquidity along with yields back to Layer2. The transaction may take up to 6 hours to finalize as Layer2.Finance aggregates users’ fund allocation intents in batches. Users will need to wait for the next batch to happen.
            </p>
          </div>
            <div className="col-sm-12 col-md-5 col-lg-5">
            <h4 className="font-w-extrabold">Withdraw from Strategy</h4>
            <p className="d-grey pt-5">
              Users can withdraw their assets along with yields from strategies by using their stTokens. The actual value that users will receive depends on the stToken amount and the price for each stToken(The price is dynamically according to the underlying DeFi protocols). Same ad deposit to strategy, the withdrawal transaction may also take up to 6 hours to finalize as users will need to wait for the next batch to happen. 
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={dwStrategy1}
              width="100%"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={dwStrategy2}
              width="100%"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <img
              src={dwStrategy3}
              width="100%"
            />
          </div>
        </div>
      </Section>
       <Section container="container-fluid">
           <div className="container">
             <div className="row">
                <div className="col-sm-12">
                    <h4 className="font-w-extrabold">Transaction History</h4>
                    <p className="d-grey pt-5">
                    Users can check their transaction history in the history tab. We devided L1 and L2 transaction history to different tabs.
                    </p>
                </div>
            </div>
           </div>
       
          <div className="row justify-content-center pt-5">
            <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={history1}
              width="100%"
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={history2}
              width="100%"
            />
          </div>
        </div>
      </Section>
       <Section container="container-fluid">
           <div className="container">
             <div className="row">
                <div className="col-sm-12">
                    <h4 className="font-w-extrabold">From 0 to v0.1</h4>
                    <p className="d-grey pt-5">
                    L2F is now on the v0.1 that is live on the Etherium Mainnet. We improve lots of user pain points that we discover while it's still an MVP running on Testnet. Here are some iteration points that we conclude from user survey and community.
                    </p>
                    <ul className="d-grey pt-5 pl-3">
                        <li><p>Users are concerned about their assets and their investment condition.</p></li>
                        <li><p>Users are not familiar with the terms and user experience in the Layer 2 products.</p></li>
                        <li><p>Users prefer a minimal and clean user interface and interaction.</p></li>
                        <li><p>Long digit of asset number cause limitation on UI design.</p></li>
                        <li><p>No auto-refresh for users' asset condition.</p></li>
                        <li><p>Not friendly for mobile users.</p></li>
                        
                    </ul>
                </div>
            </div>
           </div>
          <div className="row justify-content-center pt-5">
            <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={mvp}
              width="100%"
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <img
              src={main}
              width="100%"
            />
          </div>
        </div>
      </Section>
       <Section container="container-fluid">
           <div className="container">
            <div className="row">
          <div className="col-sm-12">
            <h4 className="font-w-extrabold">Mobile Version</h4>
            <p className="d-grey pt-5">
              Mobile version will be soon available. Interested in the project? Check it at <a target="_blank" href="https://layer2.finance">Layer2.Finance.</a>
            </p>
            </div>
          </div>
           </div>
            <div className="row justify-content-center pt-5">
           <div className="col-sm-12 col-lg-10 col-md-10">
            <img
              src={mobile}
              width="100%"
            />
          </div>
          
        </div>
      </Section>
    </>
    )
}
